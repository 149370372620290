<template>
  <div>
    <div class="card card-custom gutter-b example example-compact" v-if="filter">
      <div class="card-header">
        <div class="row my-2 align-items-center">
          <div class="col-md-1">
            <div class="input-icon">
              <input type="text" v-model="id" v-debounce:400ms="myFn" class="form-control" placeholder="Id"
                     id="kt_datatable_search_query"/>
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="input-icon">
              <input type="text" v-model="name" v-debounce:400ms="myFn" class="form-control" placeholder="Mijoz nomi"
                     id="kt_datatable_search_query"/>
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="input-icon">
              <input type="text" v-model="conttype" v-debounce:400ms="myFn" class="form-control"
                     placeholder="Kontragent nomi" id="kt_datatable_search_query"/>
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="input-icon">
              <input type="text" v-model="name" v-debounce:400ms="myFn" class="form-control"
                     placeholder="Postavshik nomi"
                     id="kt_datatable_search_query"/>
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="input-icon">
              <input type="text" v-model="inn" v-debounce:400ms="myFn" class="form-control"
                     placeholder="Korporativ karta"
                     id="kt_datatable_search_query"/>
              <span>
                <i class="flaticon2-search-1 text-muted"></i>
              </span>
            </div>
          </div>
          <div class="col">
            <a @click="reset" class="btn btn-primary font-weight-bolder">
              {{ $t('BREADCRUMBS.CLEAN') }}</a>
          </div>
        </div>

        <!-- <div class="col-lg-2 col-xl-4 mt-5 mt-lg-0 text-right">
                <v-btn small @click="reset" color="error">Tozalash</v-btn>
              </div> -->
      </div>
    </div>
    <div class="d-flex">
      <!-- kirim -->

      <div class="d-flex justify-content-between align-items-center w-100">
        <div>
          <v-btn v-for="item in getPaymentOrderTypes" small dark :key="item.id" @click="kirim(item)" class="mr-3"
                 :color="item.code == '01' ? 'success' : 'red'">
            {{ item.name }} Topshiriqnoma +
          </v-btn>
          <v-dialog v-model="dialogKirim" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Topshiriqnoma</span>
              </v-card-title>
              <v-card-text>
                <div class="input__wrapper">
                  <div class="pl-0 col-6">
                    <v-alert border="left" dense text type="success">Manba: Bank</v-alert>
                  </div>
                  <div class="px-0 col-6">
                    <div v-if="sourceType.code == '01'">
                      <v-alert border="left" dense text type="success">Turi: Kirim</v-alert>
                    </div>
                    <div v-if="sourceType.code == '02'">
                      <v-alert border="left" dense text type="error">Turi: Chiqim</v-alert>
                    </div>
                  </div>
                </div>
                <v-autocomplete v-model="typeStateTransfersInput" :items="typeStateTransfers" @change="typeTransfers"
                                item-text="name" label="Operatsiya nomi" dense item-value="id" return-object
                                outlined></v-autocomplete>
                <div>
                  <div v-show="providerSelect">
                    <v-combobox v-model="providerListInput" :items="providerList"
                                @change="providerChange(providerListInput)" label="Postavshik nomi" outlined
                                item-text="full_name"
                                dense item-value="id"></v-combobox>
                    <div v-if="getProviderContract.length == 0">
                      <v-text-field label="Postavshik Shartnoma topilmadi" outlined dense required
                                    disabled></v-text-field>
                    </div>
                    <div v-else>
                      <v-autocomplete v-model="ProviderContract" item-value="id" label="Postavshik Shartnoma"
                                      item-text="contract_number" :items="getProviderContract" outlined
                                      dense></v-autocomplete>
                    </div>
                  </div>

                  <div v-show="kontragentPayments">
                    <v-autocomplete v-model="kontragentPaymentInput" :items="getKontragent"
                                    @change="kontragentPayment(kontragentPaymentInput)"
                                    label="Kontragentlardan Tulovlar" outlined
                                    item-text="full_name" return-object dense></v-autocomplete>
                    <div v-if="getKontragentContracts.length == 0">
                      <v-text-field label="Shartnoma topilmadi" outlined required dense disabled></v-text-field>
                    </div>

                    <div v-else>
                      <v-autocomplete v-model="KontragentKontraktInput" :items="getKontragentContracts"
                                      item-text="reg_number" label="Kontragent kontrakt" item-value="id" dense
                                      outlined></v-autocomplete>
                    </div>
                  </div>

                  <div v-show="cardNumSelect2">
                    <v-combobox v-model="cardNum" :items="getAllCorCards" label="Karta raqami" outlined
                                item-text="number_16_digit" dense></v-combobox>
                  </div>

                  <div v-show="clientSelect2">
                    <v-combobox v-model="clientSelectInput" v-debounce:400ms="clientSelectDebounce"
                                @change="allClientContract(clientSelectInput)" :items="allClientNames"
                                item-text="full_name"
                                item-value="id" dense label="Mijoz nomi" outlined></v-combobox>

                    <div v-if="getContractByClient.length == ''">
                      <v-text-field label="Shartnoma topilmadi" outlined required dense disabled></v-text-field>
                    </div>
                    <div v-else>
                      <v-select v-model="ContractByClientInput" :items="getContractByClient" item-text="contract_number"
                                label="Shartnoma raqami" dense item-value="id" return-object outlined></v-select>
                    </div>
                  </div>
                </div>

                <div>
                  <v-text-field required outlined v-currency="options" v-model="summaInput" label="Summa"
                                dense></v-text-field>
                </div>
                <div>
                  <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field label="Sana" :value="date | dateFilter" append-icon="event" readonly dense outlined
                                    v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">Bekor qilish</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(date)">Saqlash</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div>
                  <v-textarea dense outlined label="Izoh" v-model="comment"></v-textarea>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="newBankLoading" color="error" @click="dialogKirimClose">Bekor qilish</v-btn>
                <v-btn :disabled="newBankLoading" color="success" @click="dialogKirimSubmit(sourceType)">
                  <i v-if="newBankLoading" class="el-icon-loading"></i>
                  Saqlash
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <a @click="filter = !filter" class="btn btn-warning font-weight-bolder">
            <span
                class="svg-icon svg-icon-white"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/General/Search.svg--><svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px"
                viewBox="0 0 25 25" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="25" height="25"/>
                  <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                  <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000" fill-rule="nonzero"/>
                </g>
              </svg><!--end::Svg Icon--></span>

            {{ $t('BREADCRUMBS.SEARCH') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      overlay: true,
      filter: false,
      conttype: '',
      type: '',
      inn: '',
      name: '',
      id: '',
      newBankLoading: false,
      comment: '',
      ProviderContract: '',
      KontragentKontraktInput: '',
      summaInput: null,
      locale: 'default',
      distractionFree: false,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      allowNegative: false,
      // currency
      kontragentPayments: false,
      providerSelect: false,
      dialog: false,
      kontragentPaymentInput: '',
      dialogKirim: false,
      dialogChiqim: false,
      clientSelect2: false,
      sourceType: '',
      cardNumSelect2: false,
      date: new Date().toISOString().substr(0, 10),
      modal: false,
      cardNum: '',
      clientSelectInput: '',
      typeStateTransfersInput: '',
      ContractByClientInput: '',
      getKontragentContracts: []
    }
  },
  created() {
    this.$store.dispatch('getPaymentOrderTypes')
    this.$store.dispatch('getPaymentSource')
    this.$store.dispatch('getKontragentsContractsListWithoutPg')
    this.$store.dispatch('getAllProvidersWithoutPg')
    if (this.$store.state.requests.filterData.data !== undefined) {
      const data = this.$store.state.requests.filterData.data
      if (
          this.$store.state.requests.filterData.path.substring(
              0,
              this.$route.path.lastIndexOf('/')
          ) == this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))
      ) {
        this.filter = true
        this.conttype = data.contract_type
        this.type = data.payment_type
        this.inn = data.client__inn__icontains
        this.name = data.client__full_name__icontains
        this.id = data.id
      } else {
        this.filter = false
        this.$store.commit('setFilterData', {
          data: undefined,
          path: ''
        })
      }
    }
  },
  computed: {
    getProviderContract() {
      return this.$store.state.requests.providerContract
    },
    getPaymentOrderTypes() {
      return this.$store.state.requests.paymentOrderTypes
    },
    getPaymentSource() {
      return this.$store.state.requests.paymentSource
    },
    providerList() {
      const data = this.$store.state.requests.allProvidersWithoutPg
      return data
    },
    getKontragent() {
      return this.$store.state.requests.kontragetnsWithoutPg
    },
    typeStateTransfers() {
      return this.$store.state.requests.typeStateTransfers
    },
    getAllCorCards() {
      const data = this.$store.state.requests.AllCorCards
      data.forEach((element) => {
        element.remain = element.remain.toLocaleString('es-US')
        element.number_16_digit = element.number_16_digit
            .toString()
            .replace(/\B(?=(\d{4})+(?!\d))/g, ' ')
      })
      return data
    },
    allClientNames() {
      return this.$store.state.requests.allClientsName
    },
    getContractByClient() {
      let data = this.$store.state.requests.oneClientContract
      return data.filter((x) => x.payment_type == 'CONT')
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [null],
        valueRange: this.valueRangeEnabled
            ? {min: this.valueRange[0], max: this.valueRange[1]}
            : undefined,
        precision: this.precisionEnabled
            ? this.precisionRangeEnabled
                ? {min: this.precisionRange[0], max: this.precisionRange[1]}
                : this.precisionFixed
            : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  methods: {
    reset() {
      this.id = ''
      this.name = ''
      this.inn = ''
      this.type = ''
      this.conttype = ''
      this.$store.commit('setFilterData', {
        data: undefined,
        path: ''
      })
      this.myFn()
    },
    myFn() {
      const data = {}
      if (this.id !== '') {
        data.id = this.id
      }
      if (this.name !== '') {
        data.client__full_name__icontains = this.name
      }
      if (this.inn !== '') {
        data.client__inn__icontains = this.inn
      }
      if (this.conttype !== '') {
        data.contract_type = this.conttype
      }
      if (this.type !== '') {
        data.payment_type = this.type
      }
      this.$store.commit('setFilterData', {
        data: data,
        path: this.$route.path
      })
      this.$store.dispatch('contractsSearch', data)
      // if (this.$route.path !== '/allcontracts/1') {
      //   this.$router.push('/allcontracts/' + '1')
      // }
    },
    dialogKirimSubmit(value) {
      const data = {
        payment_source: this.getPaymentSource.find((x) => x.code == '01').id,
        page: '1'
      }
      const transfer = {
        amount: parseInt(this.summaInput.split(',').join('')),
        oper_date: this.date,
        payment_source: this.getPaymentSource.find((x) => x.code == '01').id
      }
      const contract = {
        amount: parseInt(this.summaInput.split(',').join('')),
        oper_date: this.date,
        comment: this.comment
      }
      transfer.type_statetransfer = this.typeStateTransfersInput.id
      transfer.order_type = value.id
      if (this.typeStateTransfersInput.tag == 'card') {
        contract.contract_id = this.cardNum.id
      } else if (this.typeStateTransfersInput.tag == 'client') {
        contract.contract_id = this.ContractByClientInput.id
      } else if (this.typeStateTransfersInput.tag == 'contragent') {
        contract.contract_id = this.KontragentKontraktInput
      } else if (this.typeStateTransfersInput.tag == 'provider') {
        contract.contract_id = this.ProviderContract
      }
      this.newBankLoading = true
      this.$store
          .dispatch('postTransferData', {data, transfer, contract})
          .then(() => {
            this.newBankLoading = false
            this.dialogKirim = false
            this.cardNumSelect2 = false
            this.clientSelect2 = false
            this.kontragentPayments = false
            this.providerSelect = false
            this.ProviderContract = ''
            this.providerListInput = ''
            this.clientSelectInput = ''
            this.KontragentKontraktInput = ''
            this.kontragentPaymentInput = ''
            this.sourceType = ''
            this.cardNum = ''
            this.summaInput = ''
            this.typeStateTransfersInput = ''
            this.ContractByClientInput = ''
            this.comment = ''
          })
          .catch((err) => {
            console.error(err)
            this.newBankLoading = false
          })
    },
    kirim(code) {
      this.dialogKirim = true
      const data = {
        payment_source: this.getPaymentSource.find((x) => x.code == '01').id,
        order_type: code.id
      }
      this.$store.dispatch('getTypeStateTransfers', data)
      this.sourceType = code
    },
    allClientContract(value) {
      if (value !== null) {
        this.$store.dispatch('getOneClientContract', value.id)
      }
    },
    typeTransfers() {
      if (this.typeStateTransfersInput.tag == 'card') {
        this.kontragentPayments = false
        this.clientSelect2 = false
        this.cardNumSelect2 = true
        this.providerSelect = false

        this.$store.dispatch('getAllCorCards')
      } else if (this.typeStateTransfersInput.tag == 'client') {
        this.clientSelect2 = true
        this.cardNumSelect2 = false
        this.kontragentPayments = false
        this.providerSelect = false
      } else if (this.typeStateTransfersInput.tag == 'petrol') {
        this.cardNumSelect2 = false
        this.clientSelect2 = false
        this.kontragentPayments = false
        this.providerSelect = false
      } else if (this.typeStateTransfersInput.tag == 'contragent') {
        // kontragentPayments

        //         this.kontragentPaymentInput =

        this.clientSelect2 = false
        this.cardNumSelect2 = false
        this.kontragentPayments = true
        this.providerSelect = false
      } else if (this.typeStateTransfersInput.tag == 'provider') {
        this.cardNumSelect2 = false
        this.clientSelect2 = false
        this.kontragentPayments = false
        this.providerSelect = true
      } else if (this.typeStateTransfersInput.tag == 'salary') {
        this.cardNumSelect2 = false
        this.clientSelect2 = false
        this.kontragentPayments = false
        this.providerSelect = false
      }
    },
    clientSelectDebounce(val) {
      if (val !== null) {
        const data = {
          name: val
        }
        this.$store.dispatch('clientSearch', data)
      }
    },
    kontragentPayment(value) {
      if (value !== null) {
        this.$store.dispatch('counteragentContracts', value.id).then((res) => {
          console.log(res)
          this.getKontragentContracts = res
        })
      }
    },
    providerChange(value) {
      if (value !== null) {
        this.$store.dispatch('getProvidersContractById', value.id)
      }
    },
    dialogKirimClose() {
      this.dialogKirim = false
      this.cardNumSelect2 = false
      this.clientSelect2 = false
      this.kontragentPayments = false
      this.providerSelect = false
      this.ProviderContract = ''
      this.providerListInput = ''
      this.clientSelectInput = ''
      this.KontragentKontraktInput = ''
      this.kontragentPaymentInput = ''
      this.sourceType = ''
      this.cardNum = ''
      this.summaInput = ''
      this.typeStateTransfersInput = ''
      this.ContractByClientInput = ''
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('BREADCRUMBS.SOLD')},
      {title: this.$t('BREADCRUMBS.CONTRACTS')}
    ])
    this.overlay = false
  }
}
</script>

<style scoped>
.v-input /deep/ .v-input__prepend-outer {
  display: none !important;
}

.input__wrapper {
  display: flex;
}
</style>
