<template>
  <div>
    <v-container>
      <div class="card-header px-0">
        <div>
          <bankTopshiriqnoma />
        </div>
      </div>
    </v-container>
    <Table />
  </div>
</template>
<script>
import bankTopshiriqnoma from './bankTopshiriqnoma'
import Table from './Page'
export default {
  data: () => ({
    // amount: "",
    // order_type: "",
    // type_statetransfer: "",
    // menu: false,
    // oper_date__range_1: "",
    // menu2: false,
    // date: new Date().toISOString().substr(0, 10),
    // oper_date__range_2: "",
    // type_payment: "",
    // paymentOrderType: ""
  }),
  created() {},
  methods: {
    // reset() {
    //   this.type_payment = "";
    //   this.oper_date__range_1 = "";
    //   this.oper_date__range_2 = new Date().toISOString().substr(0, 10);
    //   this.order_type = "";
    //   this.type_statetransfer = "";
    //   this.amount = "";
    //   this.$store.commit("setFilterData", {
    //     data: undefined,
    //     path: ""
    //   });
    //   this.myFn();
    // },
    // myFn() {
    //   const data = {};
    //   data.oper_date__range = [];
    //   data.payment_source = 1;
    //   if (this.order_type !== "") {
    //     data.order_type = this.order_type;
    //   }
    //   if (this.type_statetransfer !== "") {
    //     data.type_statetransfer = this.type_statetransfer;
    //   }
    //   if (this.amount !== "") {
    //     data.amount = this.amount;
    //   }
    //   if (this.oper_date__range_1 !== "") {
    //     data.oper_date__range[0] = this.oper_date__range_1;
    //     data.oper_date__range[1] = this.oper_date__range_2;
    //   } else {
    //     delete data.oper_date__range;
    //   }
    //   this.$store.commit("setFilterData", {
    //     data: data,
    //     path: this.$route.path
    //   });
    //   this.$store.dispatch("vipiskaFilter", data);
    //   if (this.$route.path !== "/vipiska/bank/1") {
    //     this.$router.push("/vipiska/bank/" + "1");
    //   }
    // }
  },
  components: {
    bankTopshiriqnoma,
    Table
  }
}
</script>
