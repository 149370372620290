<template>
  <div>
    <!-- <v-tab-item> -->
    <v-card>
      <v-card-text>
        <div>
          <v-data-table
            :headers="headers"
            :loading="isLoading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :must-sort="true"
            @click:row="rowClick"
            item-key="oper_date"
            single-select
            :items="getAlltransactionsDaily"
            class="row-pointer"
            loading-text="Malumot yuklanmoqda..."
            no-data-text="Malumot topilmadi"
            hide-default-footer
          >
            <template v-slot:[`item.index`]="{ item }">
              {{
                getAlltransactionsDaily
                  .map(function (x) {
                    return x.oper_date
                  })
                  .indexOf(item.oper_date) + 1
              }}
            </template>
            <template v-slot:[`item.debet`]="{ item }">
              <div
                v-for="summa in item.operations"
                :key="summa.order_type__code"
              >
                <div v-if="summa.order_type__code == '01'">
                  {{ summa.amount__sum }}
                </div>
              </div>
            </template>
            <template v-slot:[`item.creadet`]="{ item }">
              <div
                v-for="summa in item.operations"
                :key="summa.order_type__code"
              >
                <div v-if="summa.order_type__code == '02'">
                  {{ summa.amount__sum }}
                </div>
              </div>
            </template>

            <template v-slot:[`item.oper_date`]="{ item }">
              {{ item.oper_date | formatDate }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <Action
                :source="getPaymentSource.find((x) => x.code == '01').id"
                :index="item"
              ></Action>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(getAlltransactionsDaily.length / 10)"
            ></v-pagination>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- </v-tab-item> -->
  </div>
</template>
<script>
import Action from './Action'
export default {
  components: {
    Action
  },
  data: () => ({
    page: 1,
    counter: 0, // count the clicks
    timer: null, // Needs to be specified here so it can be accessed on both clicks
    selectedId: -1,
    itemsPerPage: 10
  }),
  beforeCreate() {
    const data = {
      payment_source: '1'
    }
    this.$store.dispatch('getAlltransactionsDaily', data)
  },
  watch: {
    '$route.params.id': function (id) {
      this.page = parseInt(id, 10)
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    headers() {
      return [
        { text: '#', value: 'index' },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'oper_date' },
        { text: "Boshlang'ich qoldiq", value: 'starting_remain' },
        { text: 'Kirim', value: 'debet' },
        { text: 'Chiqim', value: 'creadet' },
        { text: 'Kunlik farq', value: 'daily_differ' },
        { text: 'Oxirgi qoldiq', value: 'ending_remain' },
        { text: '', value: 'action', sortable: false }
      ]
    },
    getPaymentSource() {
      return this.$store.state.requests.paymentSource
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getAlltransactionsDaily() {
      return this.$store.state.requests.allTransactionsDaily
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push(
          '/vipiska/bank/' +
            this.getPaymentSource.find((x) => x.code == '01').id +
            '/' +
            item.oper_date
        )
        row.select(false)
      }
    }

    // getPostData(value) {
    //   if (this.$store.state.requests.filterData.data !== undefined) {
    //     this.$store.dispatch("vipiskaFilterCashPerPage", {
    //       data: this.$store.state.requests.filterData.data,
    //       page: value
    //     });
    //     if (this.$route.path !== "/vipiska/bank/" + value) {
    //       this.$router.push("/vipiska/bank/" + value);
    //     }
    //   } else {
    //     const data = {
    //       payment_source: "01",
    //       page: value
    //     };
    //     this.$store.dispatch("getAllTransferListPagBank", data);
    //     if (this.$route.path !== "/vipiska/bank/" + value) {
    //     //   this.$router.push("/vipiska/bank/" + value);
    //     }
    //   }
    // }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
